import { create } from "zustand";
import {
  IntellectaVersion,
  Machine,
  PowerWord,
  Prompt,
  Role,
  Service,
  Version,
} from "../types";

export const userConfigurationStore = create<{
  // version + profile
  availableVersionsAndProfiles: Version[];
  // version
  version: IntellectaVersion | null;
  setVersion: (version: IntellectaVersion | null) => void;
  // profile
  profile: string | null;
  setProfile: (profile: string | null) => void;
  // role
  role: Role;
  // machines
  availableMachines: Machine[];
  machine: string | null;
  setMachine: (machine: string | null) => void;
  lastMachineId: string | null;
  setLastMachineId: (lastMachineId: string | null) => void;
  // power-words
  powerWords: PowerWord[];
  // prompts
  prompts: Prompt[];
  // services
  services: Service[];
}>((set) => ({
  // version + profile
  availableVersionsAndProfiles: [],
  // version
  version: null,
  setVersion: (version) => set({ version }),
  // profile
  profile: null,
  setProfile: (profile) => set({ profile }),
  // role
  role: "base",
  // machines
  availableMachines: [],
  machine: null,
  setMachine: (machine) => set({ machine }),
  lastMachineId: null,
  setLastMachineId: (lastMachineId) => set({ lastMachineId }),
  // power-words
  powerWords: [],
  // prompts
  prompts: [],
  // services
  services: [],
}));
