import { CreateOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { chatStore } from "../../store/chat-store";
import { useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";

export const NewChatButton = ({
  isRightDrawerOpen,
  rightDrawerWidth,
}: {
  isRightDrawerOpen: boolean;
  rightDrawerWidth: number;
}) => {
  const translate = useTranslate();
  const { user } = useFirebaseContext();
  const email = user?.email || "";
  const queryClient = useQueryClient();
  const { resetTimer } = sentryModeStore();
  const { clearChat } = chatStore();
  const { showBanner } = useCheckIfMessageBanner();

  const handleClick = () => {
    clearChat();
    resetTimer();
    queryClient.invalidateQueries({
      queryKey: ["intellecta-historic", email],
    });
  };

  return (
    <Tooltip
      title={translate("intellecta.new_chat")}
      enterDelay={2000}
      leaveDelay={200}
    >
      <IconButton
        color="inherit"
        aria-label="new chat"
        onClick={handleClick}
        sx={{
          position: "absolute",
          top: showBanner ? 84 : 52,
          right: 12,
          zIndex: 1,
          transform: isRightDrawerOpen
            ? `translateX(-${rightDrawerWidth}px)`
            : "translateX(0)",
          transition: "transform 0.16s ease-in-out",
        }}
      >
        <CreateOutlined />
      </IconButton>
    </Tooltip>
  );
};
