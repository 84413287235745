import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useFirebaseContext } from "@/context/firebase-context";
import {
  config,
  Config,
  IntellectaVersion,
  ProfileInfo,
  Version,
} from "../types";
import { intellectaClient } from "./intellectaClient";
import { userConfigurationStore } from "../store/user-configuration-store";

export const useGetUserConfiguration = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  const queryResult = useQuery({
    queryKey: ["intellecta-config", email],
    queryFn: async () => {
      const response = await intellectaClient<{ user: string }, Config>({
        url: "/user/config",
        payload: { user: email },
      });

      return config.parse(response);
    },
    staleTime: 1000 * 60 * 60,
    onError: () => {
      toast.error("An error occurred");
    },
  });

  // Update store Zustand con configurazione utente
  // Compliant con @tanstack/react-query v6
  const { data } = queryResult;

  useEffect(() => {
    if (data) {
      const foundVersion: Version | null = data.versions[0] || null;
      let defaultVersion: IntellectaVersion | null = null;
      const profiles: ProfileInfo[] = [];
      if (foundVersion) {
        defaultVersion = foundVersion.name;
        foundVersion.profiles.forEach((profile) => {
          profiles.push(profile);
        });
      }
      let defaultProfile: string | null = null;
      if (profiles.length > 0) {
        const defaultProfileInfo = profiles.find(
          (profile) => profile.is_active === true,
        );
        if (defaultProfileInfo) {
          defaultProfile = defaultProfileInfo.name;
        }
      }

      userConfigurationStore.setState((state) => ({
        ...state,
        availableVersionsAndProfiles: data.versions,
        availableMachines: data.machines,
        powerWords: data.powerwords,
        prompts: data.prompts,
        role: data.role,
        services: data.services,
        // Inizializzo 'version' e 'profile'
        version: state.version || defaultVersion,
        profile: state.profile || defaultProfile,
      }));
    }
  }, [data]);

  return queryResult;
};
