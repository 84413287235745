import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { IntellectaVersion } from "../types";
import { intellectaClient } from "./intellectaClient";
import { userConfigurationStore } from "../store/user-configuration-store";

type Payload = {
  user: string;
  prompt: {
    prompt: string;
    label: string;
    description: string;
    version: IntellectaVersion;
  };
};

const Response = z.object({
  value: z.union([z.literal("OK"), z.literal("ERROR")]),
});
type Response = z.infer<typeof Response>;

export const useAddPrompt = () => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseContext();
  const email = user?.email || "";
  const { version } = userConfigurationStore();

  return useMutation({
    mutationFn: async ({
      prompt,
      label,
      description,
    }: {
      prompt: string;
      label: string;
      description: string;
    }) => {
      if (!version) {
        throw new Error("An error occurred");
      }

      return await intellectaClient<Payload, Response>({
        url: "/prompt/create",
        payload: {
          user: email,
          prompt: {
            prompt,
            label,
            description,
            version,
          },
        },
      });
    },
    onError: () => {
      toast.error("An error occurred");
    },
    onSettled: (data) => {
      if (data?.value === "OK") {
        toast.success("Prompt added successfully");
        //* per scaricare di nuovo i prompt
        queryClient.invalidateQueries({
          queryKey: ["intellecta-config", email],
        });
      } else {
        toast.error("An error occurred");
      }
    },
  });
};
