import { useState } from "react";
import {
  Stack,
  Typography,
  Menu,
  MenuItem,
  ListSubheader,
  Box,
} from "@mui/material";
import { CheckRounded } from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { IntellectaVersion } from "../../types";
import { chatStore } from "../../store/chat-store";

export const VersionProfileMenu = () => {
  const translate = useTranslate();
  const {
    version,
    setVersion,
    profile,
    setProfile,
    role,
    availableVersionsAndProfiles,
  } = userConfigurationStore();
  const { clearChat } = chatStore();

  // Stato per il controllo del menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Handler per aprire e chiudere il menu
  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectVersion = ({ version }: { version: IntellectaVersion }) => {
    setVersion(version);
    clearChat();
    handleClose();
  };

  const handleSelectProfile = ({
    version,
    profile,
  }: {
    version: IntellectaVersion;
    profile: string;
  }) => {
    // fare chiamata per settare profilo attivo
    setVersion(version);
    setProfile(profile);
    clearChat();
    handleClose();
  };

  if (!version) return null;

  return (
    <>
      {/* Contenitore cliccabile */}
      <Stack
        sx={{
          border: "1px solid #fff",
          padding: 1,
          width: "100%",
          borderRadius: 1,
          overflow: "hidden",
          whiteSpace: "nowrap",
          cursor: "pointer",
        }}
        onClick={handleOpen}
      >
        <Typography
          variant="body1"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {translate(`intellecta.version_${version as IntellectaVersion}`)}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#ccc",
          }}
        >
          {profile}
        </Typography>
      </Stack>

      {/* Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: { maxHeight: 300, width: "250px" },
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {role === "base" &&
          availableVersionsAndProfiles.map((v) => (
            <MenuItem
              key={v.name}
              onClick={() => handleSelectVersion({ version: v.name })}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {translate(`intellecta.version_${v.name as IntellectaVersion}`)}
              {v.name === version && <CheckRounded />}
            </MenuItem>
          ))}
        {role === "premium" &&
          availableVersionsAndProfiles.map((v) => (
            <Box key={v.name}>
              <ListSubheader sx={{ backgroundColor: "transparent" }}>
                {translate(`intellecta.version_${v.name as IntellectaVersion}`)}
              </ListSubheader>
              {v.profiles.map((p) => (
                <MenuItem
                  key={p.name}
                  onClick={() =>
                    handleSelectProfile({ version: v.name, profile: p.name })
                  }
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {p.name}
                  {p.name === profile && <CheckRounded />}
                </MenuItem>
              ))}
            </Box>
          ))}
      </Menu>
    </>
  );
};
