import { useState } from "react";
import { Drawer, IconButton, Box, Tooltip } from "@mui/material";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { Loader } from "@/components/Layout/Loader";
import { useGetUserConfiguration } from "./api/useGetUserConfiguration";
import { ChatPanel } from "./components/ChatPanel";
import { LeftDrawer } from "./components/LeftDrawer";
import { RightDrawer } from "./components/RightDrawer";
import { InfoDialog } from "./components/InfoDialog";
import { NoVersionsMessage } from "./components/ChatPanel/NoVersionsMessage";
import { NewChatButton } from "./components/LeftDrawer/NewChatButton";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";
import { useTranslate } from "@/i18n/config";

export const LEFT_DRAWER_WIDTH = 270;
export const RIGHT_DRAWER_WIDTH = 330;
export const ICON_WIDTH = 40;

export const Intellecta = () => {
  const [userInput, setUserInput] = useState("");
  const [isShownAlert, setIsShownAlert] = useState(true);
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(true);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(true);
  const { isLoading, data } = useGetUserConfiguration();
  const { showBanner } = useCheckIfMessageBanner();
  const translate = useTranslate();

  if (isLoading || !data) {
    return <Loader isLoading />;
  }

  const toggleLeftDrawer = () => {
    setIsLeftDrawerOpen(!isLeftDrawerOpen);
  };

  const toggleRightDrawer = () => {
    setIsRightDrawerOpen(!isRightDrawerOpen);
  };

  return (
    <>
      <InfoDialog isOpen={isShownAlert} close={() => setIsShownAlert(false)} />
      <Tooltip
        title={translate("intellecta.show_hide_sidebar")}
        enterDelay={2000}
        leaveDelay={200}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleLeftDrawer}
          sx={{
            position: "absolute",
            top: showBanner ? 44 : 12,
            left: 12,
            zIndex: 1,
            transform: isLeftDrawerOpen
              ? `translateX(${LEFT_DRAWER_WIDTH}px)`
              : "translateX(0)",
            transition: "transform 0.16s ease-in-out",
          }}
        >
          {isLeftDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Tooltip>
      <NewChatButton
        isRightDrawerOpen={isRightDrawerOpen}
        rightDrawerWidth={RIGHT_DRAWER_WIDTH}
      />
      <Tooltip
        title={translate("intellecta.show_hide_sidebar")}
        enterDelay={2000}
        leaveDelay={200}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleRightDrawer}
          sx={{
            position: "absolute",
            top: showBanner ? 44 : 12,
            right: 12,
            zIndex: 1,
            transform: isRightDrawerOpen
              ? `translateX(-${RIGHT_DRAWER_WIDTH}px)`
              : "translateX(0)",
            transition: "transform 0.16s ease-in-out",
          }}
        >
          {isRightDrawerOpen ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Tooltip>
      <Box sx={{ height: "100vh" }}>
        <Drawer
          variant="persistent"
          open={isLeftDrawerOpen}
          sx={{
            width: LEFT_DRAWER_WIDTH,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: LEFT_DRAWER_WIDTH,
              boxSizing: "border-box",
              marginLeft: "64px",
            },
          }}
        >
          <LeftDrawer onPromptClick={(prompt) => setUserInput(prompt)} />
        </Drawer>

        <Box
          sx={{
            display: "flex",
            height: "100%",
            transition: (theme) =>
              theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            marginLeft: isLeftDrawerOpen
              ? `${LEFT_DRAWER_WIDTH + ICON_WIDTH + 12}px`
              : `${ICON_WIDTH + 12}px`,
            marginRight: isRightDrawerOpen
              ? `${RIGHT_DRAWER_WIDTH + ICON_WIDTH + 12}px`
              : `${ICON_WIDTH + 12}px`,
          }}
        >
          {data.versions.length > 0 ? (
            <ChatPanel
              userInput={userInput}
              setUserInput={setUserInput}
              isLeftDrawerOpen={isLeftDrawerOpen}
              isRightDrawerOpen={isRightDrawerOpen}
            />
          ) : (
            <NoVersionsMessage />
          )}
        </Box>

        <Drawer
          variant="persistent"
          anchor="right"
          open={isRightDrawerOpen}
          sx={{
            width: RIGHT_DRAWER_WIDTH,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: RIGHT_DRAWER_WIDTH,
              boxSizing: "border-box",
            },
          }}
        >
          <RightDrawer onUsePrompt={(prompt) => setUserInput(prompt)} />
        </Drawer>
      </Box>
    </>
  );
};
